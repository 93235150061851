/* @font-face for DNF Bit Bit OTF */
@font-face {
  font-family: 'bitbit';
  src: url('//cdn.df.nexon.com/img/common/font/DNFBitBit-Regular.woff'),
       url('//cdn.df.nexon.com/img/common/font/DNFBitBit-Regular.woff2');
}

.card-container.disabled {
  pointer-events: none; /* 포인터 이벤트 비활성화 */
}

.card-container.selected {
  z-index: 100; /* 확대된 카드의 z-index를 높게 설정 */
  transform: rotate(0deg) !important; /* 확대된 카드의 각도를 0으로 설정 */
  pointer-events: auto; /* 확대된 카드가 클릭 가능하도록 설정 */
}

.card-container.selected:hover {
  transform: none; /* 확대된 카드 컨테이너가 hover되지 않도록 함 */
}

.card-container:not(.selected) {
  pointer-events: none; /* 다른 카드가 클릭되지 않도록 함 */
}

.card {
  perspective: 1000px;
  width: 18vh;
  height: 28vh;
  transition: transform 0.3s, z-index 0.3s;
  transform-origin: center center;
  position: relative;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card.flipped .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.card-front {
  z-index: 2;
  transform: rotateY(0deg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-back {
  transform: rotateY(180deg);
  background-color: #2c3e50;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-wrapper {
  padding: 0.3rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: calc(100% - 10vh); /* Date 영역을 고려한 높이 조정 */
  position: relative; /* 오버레이를 위해 상대 위치 설정 */
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  bottom: 12.2222vh;
  left: -75px;
  width: 60px;
  height: 60px;
  background-image: url('https://ifh.cc/g/9wfN7X.png'); /* 닫기 아이콘 이미지 경로 */
  background-size: cover;
  cursor: pointer;
  z-index: 1000; /* z-index를 높여 아이콘이 카드 위에 표시되도록 함 */
}

.close-button:hover {
  transition: transform 0.3s, z-index 0.3s; /* hover 시 애니메이션을 위한 트랜지션 */
  transform: scale(1.1); /* 호버 시 약간 확대 */
}


.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  box-sizing: border-box;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transform: rotate(var(--random-rotation, 0deg)); /* 랜덤 각도 적용 */
  transition: transform 0.3s, z-index 0.3s; /* hover 시 애니메이션을 위한 트랜지션 */
}

.card-container:hover {
  transform: scale(1.1) rotate(var(--random-rotation, 0deg)); /* hover 시 크기 증가 */
  z-index: 1; /* hover 시 z-index 증가 */
}

.clip-icon-wrapper {
  position: absolute;
  top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.rotate-icon-wrapper {
  position: absolute;
  bottom: 12.2222vh;
  right: -75px;
  width: 60px;
  height: 60px;
  background-image: url('https://ifh.cc/g/PtvGaD.png'); /* 회전 아이콘 이미지 경로 */
  background-size: cover;
  cursor: pointer;
  z-index: 1000; /* z-index를 높여 아이콘이 카드 위에 표시되도록 함 */
}

.rotate-icon-wrapper:hover {
  transition: transform 0.3s, z-index 0.3s; /* hover 시 애니메이션을 위한 트랜지션 */
  transform: scale(1.1); /* 호버 시 약간 확대 */
}

@media (max-aspect-ratio: 1/1) {
  .card {
    perspective: 1000px;
    width: 305px;
    height: 480px;
    transition: transform 0.3s, z-index 0.3s;
    transform-origin: center center;
    position: relative;
    cursor: pointer;
  }

}

@media (min-width: 3840px) and (min-height: 2160px) {

  .close-button {
    position: absolute;
    bottom: 12.2222vh;
    left: -125px;
    width: 100px;
    height: 100px;
    background-image: url('https://ifh.cc/g/9wfN7X.png'); /* 닫기 아이콘 이미지 경로 */
    background-size: cover;
    cursor: pointer;
    z-index: 1000; /* z-index를 높여 아이콘이 카드 위에 표시되도록 함 */
  }

  .rotate-icon-wrapper {
    position: absolute;
    bottom: 12.2222vh;
    right: -125px;
    width: 100px;
    height: 100px;
    background-image: url('https://ifh.cc/g/PtvGaD.png'); /* 회전 아이콘 이미지 경로 */
    background-size: cover;
    cursor: pointer;
    z-index: 1000; /* z-index를 높여 아이콘이 카드 위에 표시되도록 함 */
  }
  

}

/* 4K 배경 이미지 설정 */
@media (min-width: 2560px) and (max-width: 3839px), (min-height: 1440px) and (max-height: 2159px) {



}



@media (max-width: 1600px) {


  .card {
    perspective: 1000px;
    width: 148.21px;
    height: 237.83px;
    transition: transform 0.3s, z-index 0.3s;
    transform-origin: center center;
    position: relative;
    cursor: pointer;
  }

  .close-button {
    position: absolute;
    bottom: 10vh;
    left: -60px;
    width: 50px;
    height: 50px;
    background-image: url('https://ifh.cc/g/9wfN7X.png'); /* 닫기 아이콘 이미지 경로 */
    background-size: cover;
    cursor: pointer;
    z-index: 1000; /* z-index를 높여 아이콘이 카드 위에 표시되도록 함 */
  }

  .rotate-icon-wrapper {
    position: absolute;
    bottom: 10vh;
    right: -60px;
    width: 50px;
    height: 50px;
    background-image: url('https://ifh.cc/g/PtvGaD.png'); /* 회전 아이콘 이미지 경로 */
    background-size: cover;
    cursor: pointer;
    z-index: 1000; /* z-index를 높여 아이콘이 카드 위에 표시되도록 함 */
  }

}


@media (max-width: 1200px)  {

  .card {
    perspective: 1000px;
    width: calc(144.78px * 0.8);
    height: calc(232.32px * 0.8);
    transition: transform 0.3s, z-index 0.3s;
    transform-origin: center center;
    position: relative;
    cursor: pointer;
  }
}


@media (max-width: 768px) {

  .close-button {
    position: absolute;
    bottom: 10vh;
    left: -60px;
    width: 50px;
    height: 50px;
    background-image: url('https://ifh.cc/g/9wfN7X.png'); /* 닫기 아이콘 이미지 경로 */
    background-size: cover;
    cursor: pointer;
    z-index: 1000; /* z-index를 높여 아이콘이 카드 위에 표시되도록 함 */
  }

  .rotate-icon-wrapper {
    position: absolute;
    bottom: 10vh;
    right: -60px;
    width: 50px;
    height: 50px;
    background-image: url('https://ifh.cc/g/PtvGaD.png'); /* 회전 아이콘 이미지 경로 */
    background-size: cover;
    cursor: pointer;
    z-index: 1000; /* z-index를 높여 아이콘이 카드 위에 표시되도록 함 */
  }

  .card {
    perspective: 1000px;
    width: 139.76px;
    height: 224.27px;
    transition: transform 0.3s, z-index 0.3s;
    transform-origin: center center;
    position: relative;
    cursor: pointer;
  }
}



@media (max-width: 480px) {

  .card {
    perspective: 1000px;
    width: 144.78px;
    height: 232.32px;
    transition: transform 0.3s, z-index 0.3s;
    transform-origin: center center;
    position: relative;
    cursor: pointer;
  }

   .rotate-icon-wrapper {
    display: none;
  }

  .clip-icon-wrapper {
    position: absolute;
    scale: 0.9;
    top: -0.8rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }

 
  .close-button {
    position: absolute;
    top: -1rem; /* 상단으로 위치 조정 */
    right: -1rem; /* 우측으로 위치 조정 */
    left: auto;
    width: 40px;
    height: 40px;
    background-image: url('https://ifh.cc/g/9wfN7X.png'); /* 닫기 아이콘 이미지 경로 */
    background-size: cover;
    cursor: pointer;
    z-index: 1000; /* z-index를 높여 아이콘이 카드 위에 표시되도록 함 */
  }

}



@media (max-width: 320px) {


  .card {
    perspective: 1000px;
    width: 97.75px;
    height: 133.93px;
    transition: transform 0.3s, z-index 0.3s;
    transform-origin: center center;
    position: relative;
    cursor: pointer;
  }


   .rotate-icon-wrapper {
    display: none;
  }

  .clip-icon-wrapper {
    position: absolute;
    scale: 0.7;
    top: -0.9rem;
    left: 45%;
    transform: translateX(-50%);
    z-index: 3;
  }

  .close-button {
    position: absolute;
    top: -1rem; /* 상단으로 위치 조정 */
    right: -1rem; /* 우측으로 위치 조정 */
    left: auto;
    width: 40px;
    height: 40px;
    background-image: url('https://ifh.cc/g/9wfN7X.png'); /* 닫기 아이콘 이미지 경로 */
    background-size: cover;
    cursor: pointer;
    z-index: 1000; /* z-index를 높여 아이콘이 카드 위에 표시되도록 함 */
  }
  
}