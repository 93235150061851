/* @font-face for DNF Bit Bit OTF */
@font-face {
  font-family: 'bitbit';
  src: url('//cdn.df.nexon.com/img/common/font/DNFBitBit-Regular.woff'),
       url('//cdn.df.nexon.com/img/common/font/DNFBitBit-Regular.woff2');
}

@font-face {
  font-family: 'TmoneyRoundWindExtraBold';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/TmoneyRoundWindExtraBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* 첫 주차를 위한 grid 설정 */
.grid.first-week {
  grid-template-columns: repeat(4, 1fr); /* 4열 */
  width: 45%;
}

.grid.last-week {
  grid-template-columns: repeat(6, 1fr); /* 6열 */
  width: 80%; /* 너비를 조정 */
}


body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Noto Sans KR', sans-serif;
  background: url('https://ifh.cc/g/ocM559.jpg') no-repeat center top fixed;
  background-size: cover;
  background-attachment: fixed;
}

.container {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: cover;
}

/* .week-navigation 설정 */
.week-navigation {
  position: fixed; /* position 속성을 fixed로 설정 */
  top: 32vh; /* 고정된 위치를 픽셀 단위로 설정 */
  left: 50%; /* 가로 중앙 정렬 */
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 2;
  width: 100%; /* 고정된 너비 */
  height: auto; /* 자동 높이 */
}

.week-button {
  font-family: 'TmoneyRoundWindExtraBold';
  font-weight: 800;
  padding: 0.0rem 0rem;
  margin: 0 0.5rem;
  font-size: 22px; /* 폰트 크기를 vw로 조정 */
  border: none;
  cursor: pointer;
  background: none; /* 배경 제거 */
  color: #D7F2FF; /* 기본 상태를 옅은 회색으로 설정 */
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.week-button.active {
  color: #ffffff; /* 활성화된 버튼을 흰색으로 설정 */
  background: none; /* 배경 제거 */
}

.week-button:hover:not(.active) {
  color: #ffffff;
}

.week-button.inactive {
  opacity: 0.6;
  pointer-events: none;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  width: 75%;
  margin-top: 5rem;
  padding: 1rem;
  box-sizing: border-box;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transform: rotate(var(--random-rotation, 0deg));
  transition: transform 0.3s, z-index 0.3s;
}

.card-container.disabled {
  pointer-events: none; /* 포인터 이벤트 비활성화 */
}

.card-container.selected {
  z-index: 100; /* 확대된 카드의 z-index를 높게 설정 */
  transform: rotate(0deg) !important; /* 확대된 카드의 각도를 0으로 설정 */
  pointer-events: auto; /* 확대된 카드가 클릭 가능하도록 설정 */
}

.card-container.selected:hover {
  transform: none; /* 확대된 카드 컨테이너가 hover되지 않도록 함 */
}

.card-container:not(.selected) {
  pointer-events: none; /* 다른 카드가 클릭되지 않도록 함 */
}

.separator {
  color: #FFFFFF80;
  font-size: 12px; /* 구분 기호 크기 조정 */
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* 구분 기호는 이벤트를 받지 않도록 설정 */
  margin: 0 0.25rem; /* 구분 기호의 마진을 줄여서 간격을 줄임 */
}

/* 가로보다 세로가 더 긴 경우 두 줄로 카드 배치 */
@media (max-aspect-ratio: 1/1) {
  .week-navigation {
    top: 32vh; /* 해상도에 맞춘 위치 설정 */
  }

  .week-button {
    font-size: 3rem; /* 폰트 크기를 vw로 조정 */
  }

  .grid {
    margin-top: 100px;
    grid-template-columns: repeat(4, 1fr); /* 4열 */
    width: 85%;
    grid-row-gap: 60px; /* 카드 사이의 위아래 간격 설정 */
    height: 580px; 
  }

  .grid > .card-container:nth-child(5),
  .grid > .card-container:nth-child(6),
  .grid > .card-container:nth-child(7) {
    position: relative; 
    left: 50% !important;
  }
}


/* 4K 배경 이미지 설정 */
@media (min-width: 3840px) and (min-height: 2160px) {

  body, html {
    background: url('https://ifh.cc/g/3nBjaY.jpg') no-repeat center top fixed;
    background-size: cover;
  }

  .grid.first-week {
    grid-template-columns: repeat(4, 1fr); /* 4열 */
    width: 50%;
  }
  

  /* .week-navigation 설정 */
  .week-navigation {
    position: fixed; /* position 속성을 fixed로 설정 */
    top: 32vh;/* 고정된 위치를 픽셀 단위로 설정 */
    left: 50%; /* 가로 중앙 정렬 */
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    z-index: 2;
    width: 100%; /* 고정된 너비 */
    height: auto; /* 자동 높이 */
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    width: 85%;
    margin-top: 5rem;
    padding: 1rem;
    box-sizing: border-box;
  }

  .separator {
    color: #FFFFFF80;
    font-size: 32px; /* 구분 기호 크기 조정 */
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none; /* 구분 기호는 이벤트를 받지 않도록 설정 */
    margin: 0 0.25rem; /* 구분 기호의 마진을 줄여서 간격을 줄임 */
  }
  

  .week-button {
    font-family: 'TmoneyRoundWindExtraBold';
    font-weight: 800;
    padding: 0.0rem 0.5rem;
    margin: 0 0.5rem;
    font-size: 22px; /* 폰트 크기를 vw로 조정 */
    border: none;
    cursor: pointer;
    background: none; /* 배경 제거 */
    color: #D7F2FF; /* 기본 상태를 옅은 회색으로 설정 */
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  .week-button {
    font-size: 3rem; /* 폰트 크기를 vw로 조정 */
  }
}

/* 2560x1440 해상도 설정 */
@media (min-width: 2560px) and (max-width: 3839px), (min-height: 1440px) and (max-height: 2159px) {

  .week-navigation {
    top: 32vh;/* 해상도에 맞춘 위치 설정 */
  }

  .grid.first-week {
    grid-template-columns: repeat(4, 1fr); /* 4열 */
    width: 50%;
  }
  

  .grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    width: 88%;
    margin-top: 5rem;
    padding: 1rem;
    box-sizing: border-box;
  }
  
  .separator {
    color: #FFFFFF80;
    font-size: 32px; /* 구분 기호 크기 조정 */
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none; /* 구분 기호는 이벤트를 받지 않도록 설정 */
    margin: 0 0.25rem; /* 구분 기호의 마진을 줄여서 간격을 줄임 */
  }
  

  .week-button {
    font-family: 'TmoneyRoundWindExtraBold';
    font-weight: 800;
    padding: 0.0rem 0.5rem;
    margin: 0 0.5rem;
    font-size: 22px; /* 폰트 크기를 vw로 조정 */
    border: none;
    cursor: pointer;
    background: none; /* 배경 제거 */
    color: #D7F2FF; /* 기본 상태를 옅은 회색으로 설정 */
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  .week-button {
    font-size: 2rem; /* 폰트 크기를 vw로 조정 */
  }
}

@media (max-width: 1600px) {


  body, html {
    height: 100%; /* 전체 높이를 차지하도록 설정 */
    margin: 0; /* 기본 여백 제거 */
    padding: 0; /* 기본 패딩 제거 */
    background: url('https://ifh.cc/g/317D7B.jpg') no-repeat fixed;
    background-position: center top !important; /* 배경 이미지를 강제로 위로 이동 */
    background-size: cover; /* 배경 이미지를 전체 화면에 맞추도록 조정 */
  }

  .week-navigation {
    top: 34vh; /* 해상도에 맞춘 위치 설정 */
  }

  .grid.first-week {
    grid-template-columns: repeat(4, 1fr); /* 4열 */
    width: 50%;
  }
  

  .grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    width: 83%;
    padding: 1.5rem;
    box-sizing: border-box;
  }
}


@media (max-width: 1440px) {

  .grid.first-week {
    grid-template-columns: repeat(4, 1fr); /* 4열 */
    width: 60%;
  }
  

  .week-navigation {
    top: 33vh; /* 해상도에 맞춘 위치 설정 */
  }
  
}

@media (max-width: 1200px) {

  body, html {
    height: 100%; /* 전체 높이를 차지하도록 설정 */
    margin: 0; /* 기본 여백 제거 */
    padding: 0; /* 기본 패딩 제거 */
    background: url('https://ifh.cc/g/CMMqcr.jpg') no-repeat fixed;
    background-position: center top !important; /* 배경 이미지를 강제로 위로 이동 */
    background-size: cover; /* 배경 이미지를 전체 화면에 맞추도록 조정 */
  }

  .week-navigation {
    top: 43vh; /* 해상도에 맞춘 위치 설정 */
  }

  
  .week-button {
    font-size: 20px; /* 폰트 크기를 vw로 조정 */
  }

}

@media (max-width: 1024px)  {

  .grid {
    margin-top: 200px;
    grid-template-columns: repeat(4, 1fr); /* 4열 */
    width: 600px;
    height: 450px; 
  }

  .grid.last-week {
    grid-template-columns: repeat(4, 1fr); /* 4열 */
    width: 600px;
  }
  

  .week-navigation {
    top: 34vh; /* 해상도에 맞춘 위치 설정 */
  }

  .grid > .card-container:nth-child(5),
  .grid > .card-container:nth-child(6),
  .grid > .card-container:nth-child(7) {
    position: relative; 
    left: 50% !important;
  }

}

/* 반응형 디자인 추가 */
@media (max-width: 768px){

  body, html {
    height: 100%; /* 전체 높이를 차지하도록 설정 */
    margin: 0; /* 기본 여백 제거 */
    padding: 0; /* 기본 패딩 제거 */
    background: url('https://ifh.cc/g/JP06sv.jpg') no-repeat fixed;
    background-position: center top !important; /* 배경 이미지를 강제로 위로 이동 */
    background-size: cover; /* 배경 이미지를 전체 화면에 맞추도록 조정 */
  }

  .grid.first-week {
    grid-template-columns: repeat(4, 1fr); /* 4열 */
    width: 95%;
  }
  

  .week-navigation {
    top: 32vh; /* 해상도에 맞춘 위치 설정 */
  }

  .week-button {
    font-size: 18px; /* 폰트 크기를 vw로 조정 */
  }

  .grid {
    margin-top: 200px;
    grid-template-columns: repeat(4, 1fr); /* 4열 */
    width: 720px;
    height: 580px; 
  }

  .grid > .card-container:nth-child(5),
  .grid > .card-container:nth-child(6),
  .grid > .card-container:nth-child(7) {
    position: relative; 
    left: 50% !important;
  }

}

/* 반응형 디자인 추가 */
@media (max-width: 480px) {
  body, html {
    height: 100%; /* 전체 높이를 차지하도록 설정 */
    margin: 0; /* 기본 여백 제거 */
    padding: 0; /* 기본 패딩 제거 */
    background: url('https://ifh.cc/g/1HCNFA.jpg') no-repeat fixed;
    background-position: center top !important; /* 배경 이미지를 강제로 위로 이동 */
    background-size: cover; /* 배경 이미지를 전체 화면에 맞추도록 조정 */
  }

    /* 첫 주차를 위한 grid 설정 */
  .grid.first-week {
    grid-template-columns: repeat(2, 1fr); /* 2열 */
    width: auto;
  }

  .container {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-size: cover;
  }

  .grid {
    margin-top: 12rem;
    grid-template-columns: repeat(2, 1fr); /* 2열 */
    grid-template-rows: auto; /* 자동 높이 */
    width: 380px !important;
    height: 100% !important;
    overflow-y: auto; /* 세로 스크롤 가능하도록 설정 */
    padding-bottom: 25rem; /* 스크롤 공간 확보를 위해 추가 패딩 */
  }

  .card-container {
    margin-top: 16px;
  }

 /* .week-navigation 설정 */
.week-navigation {
  position: fixed; /* position 속성을 fixed로 변경 */
  top: 24vh !important; /* 원하는 고정 위치 설정 */
  left: 50%; /* 가로 중앙 정렬 */
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 2;
  width: 100%; /* 고정된 너비 */
  height: auto; /* 자동 높이 */
}

.grid.last-week {
  margin-top: 12rem;
  grid-template-columns: repeat(2, 1fr); /* 2열 */
  grid-template-rows: auto; /* 자동 높이 */
  width: 380px !important;
  height: 100% !important;
  overflow-y: auto; /* 세로 스크롤 가능하도록 설정 */
  padding-bottom: 25rem; /* 스크롤 공간 확보를 위해 추가 패딩 */
}

  .week-button {
    font-size: 18px; /* 폰트 크기를 더 크게 조정 */
  }

  .separator {
    font-size: 12px; /* 구분 기호 크기 조정 */
  }


  .grid > .card-container:nth-child(5),
  .grid > .card-container:nth-child(6) {
    position: relative; 
    left: 0% !important;
  }
}

@media (max-width: 320px)  {

  body, html {
    height: 100%; /* 전체 높이를 차지하도록 설정 */
    margin: 0; /* 기본 여백 제거 */
    padding: 0; /* 기본 패딩 제거 */
    background: url('https://ifh.cc/g/1HCNFA.jpg') no-repeat fixed;
    background-position: center top !important; /* 배경 이미지를 강제로 위로 이동 */
    background-size: cover; /* 배경 이미지를 전체 화면에 맞추도록 조정 */
  }

      /* 첫 주차를 위한 grid 설정 */
      .grid.first-week {
        grid-template-columns: repeat(2, 1fr); /* 2열 */
        width: auto;
      }


      .grid.last-week {
        margin-top: 13rem;
        grid-template-columns: repeat(2, 1fr); /* 2열 */
        grid-template-rows: auto; /* 자동 높이 */
        width: 320px !important;
        height: 100% !important;
        overflow-y: auto; /* 세로 스크롤 가능하도록 설정 */
        padding-bottom: 400px; /* 스크롤 공간 확보를 위해 추가 패딩 */
      }
    

  .container {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-size: cover;
  }

  .grid {
    margin-top: 13rem;
    grid-template-columns: repeat(2, 1fr); /* 2열 */
    grid-template-rows: auto; /* 자동 높이 */
    width: 320px !important;
    height: 100% !important;
    overflow-y: auto; /* 세로 스크롤 가능하도록 설정 */
    padding-bottom: 400px; /* 스크롤 공간 확보를 위해 추가 패딩 */
  }

  .card-container {
    margin-top: 16px;
  }

  .week-button {
    font-size: 18px !important; /* 폰트 크기를 더 크게 조정 */
  }

  .week-navigation {
    position: fixed; /* position 속성을 fixed로 변경 */
    top: 24vh !important; /* 원하는 고정 위치 설정 */
    left: 50%; /* 가로 중앙 정렬 */
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    z-index: 2;
    width: 100%; /* 고정된 너비 */
    height: auto; /* 자동 높이 */
  }
  

  .grid > .card-container:nth-child(5),
  .grid > .card-container:nth-child(6) {
    position: relative; 
    left: 0% !important;
  }
}